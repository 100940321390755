import React from 'react';

function NotFound(props)
{
  return (
    <div>
      Whoops... go back and try again!
    </div>
  );
}

export default NotFound;